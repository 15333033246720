import * as React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import hbg from "../../images/hbg-10.jpg"

import Chevron from "../../images/chevron.inline.svg"
import Arrow from "../../images/icon-arrow-right.inline.svg"

const PageContain = styled.div`
    min-height: 60vh;
    font-family: "BS";
`

const PageHeader = styled.div`
  
  background: url(${hbg});
  background-size: cover;
  background-position: top center;
  height: 100%;
  width: 100%;
  padding: 7rem 1rem 3rem;

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--white);
    letter-spacing: 0.1rem;
  }

  @media (min-width: 550px) {

    padding: 8rem 2.5rem 3rem;
  }


  @media (min-width: 768px) {

    /* padding: 10rem 6rem 4rem; */

    h1 {
      font-size: 2rem;
    }
  }

  @media (min-width: 1024px) {

    padding: 12rem 2.5rem 4rem;

    h1 {
      font-size: 2.6rem;
    }
  }
`

const PageHeaderInner = styled.div`

  @media (min-width: 1490px) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }
`


const PageContent = styled.div`

  padding: 2rem 1rem 3rem;

  @media (min-width: 550px) {
    padding: 2rem 2.5rem 3rem;
  }


  @media (min-width: 768px) {
    /* padding: 4rem 2.5rem 4rem; */
  }

  @media (min-width: 1024px) {
    padding: 4rem 2.5rem 4rem;
    }
`


const PageContentInner = styled.div`

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media (min-width: 1490px) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }
`

const Sidebar = styled.div`
  margin-right: 4rem;
  margin-top: 1rem;
  width: 200px;

  @media (max-width: 900px) {
    display: none;
  }

  ul {
    li {
      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: 2px solid rgba(0,0,0,.2);
      font-weight: 500;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
          height: 24px;
          margin-top: 1px;
          width: auto;
          margin-right: 2px;
          fill: var(--primary);
          margin-left: 0;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    margin-right: 5rem;
  }

  @media (min-width: 1440px) {
    margin-right: 10rem;
  }
`

const MainContent = styled.div`
  flex: 1;

  h2 {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.05rem;
    margin-bottom: 3rem;

    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.05rem;
    margin-bottom: 1rem;
    padding-bottom: .75rem;
    border-bottom: 3px solid rgba(0,0,0,.2);
    max-width: 800px;

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }

    @media (min-width: 1200px) {
      max-width: none;
    }
  }

  h4 {
    font-size: .95rem;
    font-weight: 600;
    letter-spacing: 0.01rem;
    margin-bottom: .75rem;
    display: flex;
    align-items: center;

    &:first-of-type {margin-top:2rem;}

    span {
      font-weight: 500;
    }

    svg {
      height: 12px;
      width: auto;
      /* transform: rotate(90deg); */
      margin-right: 10px;
      fill: var(--primary);
    }

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  p {
    font-size: 1rem;
    line-height: 140%;
    max-width: 800px;
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }
`

const ContentBlock = styled.div`
  margin-bottom: 5rem;

  a {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.01rem;
    margin-bottom: .75rem;
    display: flex;
    align-items: center;
  }

      svg {
      height: 12px;
      width: auto;
      /* transform: rotate(90deg); */
      margin-left: 10px;
      fill: var(--primary);
    }
`

const TeamMember = styled.div`
  margin-bottom: 2rem;
`

const Value = styled.div`
  /* margin-bottom: 2rem; */

  svg {
    height: 12px;
    width: auto;
    transform: rotate(90deg);
    margin-left: 10px;
    fill: var(--primary);
  }

`
const Accent = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-bottom: 4px solid var(--primary);
  width: 70px;
`

const ValuesGrid = styled.div`
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    max-width: 800px;

    @media (min-width: 900px) {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
    }
`

const MainContentHeading = styled.div`
  margin-bottom: 4rem;
`



const ServicesPage = () => (
  <Layout>
    <Seo title="Services" />
    <PageContain>
      <PageHeader>
        <PageHeaderInner>
          <h1>Services</h1>
        </PageHeaderInner>
      </PageHeader>
      <PageContent>
        <PageContentInner>
          <Sidebar>
            <ul>
              <li><Link to="/services/development" stripHash><Chevron/>Development</Link></li>
              <li><Link to="/services/construction" stripHash><Chevron/>General Construction</Link></li>
              <li><Link to="/services/subcontracting" stripHash><Chevron/>Sub Contractor</Link></li>
            </ul>
          </Sidebar>
          <MainContent>
            <MainContentHeading>
              <h2>We do it all</h2>
              <Accent/>
              <p>Regardless of the method of delivery, from construction management to design/build to general contracting to self-performance of trades, Orion is there every step with you. Our team of experienced builders collaborates closely with you and the entire project team to make your vision a reality.</p>
            </MainContentHeading>
            <ContentBlock id="development">
              <h3>Development Services</h3>
              <p>From initial planning stages to completion, Orion provides a unique design/build delivery system by teaming with highly skilled architects and project managers to meet our client’s specific needs. Our design/build team has extensive project experience that includes multi-family, hospitality, commercial, renovations, industrial sites and much more.</p>
              <Link to="/services/development">See all development services <Arrow/></Link>
            </ContentBlock>
            <ContentBlock id="construction">
              <h3>General Construction Services</h3>
              <p>Orion's cost conscious, high-quality approach to Construction Management (CM) is the corner stone of our business. Our team of professionals is with you every step of the way.  Our clients have come to depend on our expertise, professionalism and ability to get the job done right and on time.</p>
              <Link to="/services/construction">See all general construction services <Arrow/></Link>
            </ContentBlock>
            <ContentBlock id="subcontracting">
              <h3>Sub Contractor Services</h3>
              <p>Self-perform is an often used but poorly understood phrase in the construction industry. When self-performing, the general contractor on a project completes certain construction activities with its own skilled labor force. In Orion's case, this includes wood and metal stud framing, drywall and paint, cabinets and counters and mechanical and electrical services.</p>
              <Link to="/services/subcontracting">See all sub contracting services <Arrow/></Link>
            </ContentBlock>
          </MainContent>
        </PageContentInner>
      </PageContent>
    </PageContain>
  </Layout>
)

export default ServicesPage
